
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { ImageAsset } from '@/components/atom/Image';
import { PageHeadline } from '@/components/atom/PageHeadline';
import { TokenCard, TokenCardProps } from '@/components/molecule/TokenCard';
import { MAINNET } from '@/constants/env';
import { TOKEN_CONFIG, TOKEN_LIST } from '@/constants/tokens';
import { Token, TokenVocab } from '@/constants/types';
import { useHelpCenterState } from '@/hooks/support/help-center';
import { useTranslation } from '@/hooks/support/translate';
import styles from '@/styles/Global.module.css';
import { getTotalStake } from '@/utils/claystack';
import { serializeData, SerializedData } from '@/utils/common';
import { getContract } from '@/utils/ether';
import { getContractFunds } from '@/utils/http/aptos';
import { getPrices } from '@/utils/http/get-prices';
import { getAssetBySymbolForIndex, getBackendProvider, getNetworkByToken } from '@/utils/utils';
import classNames from 'classnames';
import { BigNumber } from 'ethers';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';

const Stake = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
    const { t } = useTranslation();
    const router = useRouter();
    const {
        helpCenterState: { isOpen: isHelpCenterOpen },
    } = useHelpCenterState();
    const testnetTitle = !MAINNET ? `${t('common.testnet')} ` : '';
    const title = `ClayStack ${testnetTitle}| ${t('common.title')}`;

    const cardsMap = Object.entries(TOKEN_CONFIG).reduce((acc, [t, config]) => {
        if ((MAINNET && !config.vocab.inMainnet) || (!MAINNET && !config.vocab.inTestNet)) return acc;
        const token = t as Token;
        const vocab: TokenVocab = config.vocab;
        const parentToken = vocab.parentId;
        const network = MAINNET ? config.mainnet : config.testnet;
        if (!(parentToken in acc)) {
            acc[parentToken] = {
                id: token,
                title: vocab.name,
                tokenAsset: getAssetBySymbolForIndex(vocab.symbol) as ImageAsset,
                apy: props[token].apy,
                tvl: props[token].tvl ? BigNumber.from(props[token].tvl) : null,
                networks: [],
                styles: vocab.styles,
                onClick: (slug) => router.push(`/stake/${slug}`),
            };
        }
        acc[parentToken].networks!.push({
            id: network._id,
            caption: MAINNET ? network.family.name : network.name,
            icon: network.family.icon,
            slug: vocab.slug,
        });
        return acc;
    }, {} as Record<Token, TokenCardProps>);
    const cardCount = Object.keys(cardsMap).length;
    return (
        <>
            <Head>
                <title>{title}</title>
            </Head>
            <div className={styles.page}>
                <div
                    className={classNames(
                        'flex flex-col items-center justify-center w-full px-4 pt-12 my-auto xl:px-0',
                        {
                            'xl:px-24': !isHelpCenterOpen,
                            'xl:px-4': isHelpCenterOpen,
                        },
                    )}
                >
                    <div className="fixed top-0 z-[-1] index-gradient"></div>
                    <PageHeadline title={t('slug.stakeCard.stake')} caption={t('index.caption')} align="center" />
                    <div
                        className={classNames({
                            'grid w-full grid-cols-1 gap-4 px-4 pb-12 md:grid-cols-2 xl:grid-cols-3 md:gap-8 md:w-auto':
                                cardCount > 2,
                            'flex justify-center space-x-8': cardCount < 3,
                        })}
                    >
                        {Object.values(cardsMap).map((cardProps, index) => (
                            <TokenCard key={index} {...cardProps} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

const getTokenDetails = async (token: Token): Promise<Pick<TokenCardProps, 'apy' | 'tvl'>> => {
    try {
        const id = TOKEN_CONFIG[token]!.vocab.isChild
            ? TOKEN_CONFIG[token]!.vocab.parentId
            : TOKEN_CONFIG[token]!.vocab._id;
        if (id === 'APTOS') {
            const aptosFunds = await getContractFunds();
            return {
                tvl: BigNumber.from(aptosFunds.data.liquidity).mul(10 ** 10),
                apy: 0.11,
            };
        }
        const network = getNetworkByToken(id!)._id;
        const provider = await getBackendProvider(network);
        const contract = await getContract({
            contractName: 'ClayMain',
            network,
            provider,
            token: id!,
        });
        let version = TOKEN_CONFIG[id]!.version[network];
        const totalStake = await getTotalStake(contract);

        const prices = await getPrices(id!, network, version!);
        return {
            tvl: totalStake ?? 0,
            apy: prices?.apy ?? null,
        };
    } catch (e) {
        return {
            tvl: null,
            apy: null,
        };
    }
};

type SerializedTokenDetails = Record<Token, SerializedData<Pick<TokenCardProps, 'apy' | 'tvl'>>>;

 const _getStaticProps: GetStaticProps<SerializedTokenDetails> = async () => {
    const details = TOKEN_LIST.map(async (token) => ({
        _id: token,
        details: await getTokenDetails(token),
    }));
    const results = await Promise.all(details);

    const props = results.reduce((acc, data) => {
        // @ts-expect-error
        acc[data._id as Token] = serializeData(data.details);
        return acc;
    }, {} as SerializedTokenDetails);

    return {
        props,
        revalidate: 60, //60 * 60,
    };
};

export default Stake;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  