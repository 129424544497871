import { Button } from '@/components/atom/Button';
import { Image, ImageAsset } from '@/components/atom/Image';
import { TextDetail } from '@/components/atom/TextDetail';
import { NetworkFamilyConfig, Token, TokenVocab } from '@/constants/types';
import { useMixpanel } from '@/hooks/mixpanel/mixpanel';
import { useTranslation } from '@/hooks/support/translate';
import { formatNumberHuman, formatNumberPercent, Maybe } from '@/utils/common';
import { getVocabByToken } from '@/utils/utils';
import classNames from 'classnames';
import { BigNumber, constants } from 'ethers';
import { useState } from 'react';
import { ensuredForwardRef } from 'react-use';

type TokenNetworkOptions = {
    id: string;
    caption: string;
    slug: string;
    icon: NetworkFamilyConfig['icon'];
};
export type TokenCardProps = {
    id: Token;
    title: string;
    tokenAsset: ImageAsset;
    networks: TokenNetworkOptions[];
    tvl: Maybe<BigNumber>;
    apy: Maybe<number>;
    styles: TokenVocab['styles'];
    onClick: (slug: string) => void;
};
export const TokenCard = ensuredForwardRef<HTMLDivElement, TokenCardProps>(
    ({ id, title, tokenAsset, networks, tvl, apy, styles, onClick }, ref) => {
        const { t } = useTranslation();
        const { track } = useMixpanel();

        const tokenVocab = getVocabByToken(id);
        const [isHovered, setIsHovered] = useState(false);
        const handleHover = (isHoverState: boolean) => {
            setIsHovered(isHoverState);
        };
        const handleClick = (network: TokenNetworkOptions) => {
            onClick(network.slug);
            track(`CARD-BUTTON-${network.caption}`);
        };
        return (
            <div
                className={classNames(
                    'relative w-full md:w-[20rem] h-full md:h-[25rem] rounded-lg bg-gradient-to-t from-[#5700fe] to-grey-50/70 ring-1 ring-white/5',
                )}
                ref={ref}
                id={id}
            >
                {/* bottom up arrow layer */}
                <div className="absolute bottom-0 flex flex-col w-full h-1/3">
                    <div
                        style={{
                            width: '0',
                            height: '0',
                            borderStyle: 'solid',
                            borderWidth: '0 160px 40px 160px',
                            borderColor: 'transparent transparent rgba(255,255,255,0.15) transparent',
                        }}
                    ></div>
                    <div className="w-full h-full rounded-b-lg bg-white/20"></div>
                </div>
                {/* background layer for hover effects and subtle black to transparent gradient top to bottom */}
                <div className="absolute w-full h-full rounded-lg bg-gradient-to-b from-black/10 to-black/60">
                    <div
                        id="bg-container"
                        className={classNames('transition-opacity duration-300 opacity-0 gr--blue', {
                            'opacity-90': isHovered,
                        })}
                        style={{
                            height: '90%',
                            width: '80%',
                            top: '10%',
                            left: 0,
                            filter: 'blur(35px)',
                        }}
                    >
                        <div
                            className="gr--red"
                            style={{
                                top: '15%',
                                right: '10%',
                                height: '25%',
                                width: '30%',
                            }}
                        ></div>
                    </div>
                </div>
                <div className="relative z-20 flex flex-col h-full">
                    <div className="flex flex-col gap-2 p-6">
                        <div className="flex items-end justify-between">
                            <div className="text-white t__heading--h6">{title}</div>
                            <TextDetail
                                orientation="horizontal"
                                align="right"
                                caption="APY"
                                valueClassName="t__heading--h6"
                                value={apy ? formatNumberPercent(apy * 100, 2) : '0%'}
                            />
                        </div>
                        <div className="flex items-end justify-between">
                            <div className="tracking-[1.5px] text-[10px] leading-[16px]  c-secondary--text">
                                {tokenVocab.symbol}
                            </div>
                            <TextDetail
                                orientation="horizontal"
                                align="right"
                                caption="TVL"
                                size="small"
                                value={`${formatNumberHuman(
                                    tvl ? tvl.div(constants.WeiPerEther).toNumber() : 0,
                                    'standard',
                                )}`}
                            />
                        </div>
                        <div className="flex items-center justify-center w-full mt-4">
                            <div
                                className="z-20 h-[171px] w-[192px] bg-pointer-events-none bg-contain bg-no-repeat bg-center"
                                style={{
                                    backgroundImage: `url(${styles!.backgroundImage})`,
                                }}
                            />
                        </div>
                    </div>
                    <div className="mt-auto">
                        <div className="flex items-center px-4 md:px-6">
                            <div className="w-full h-px bg-white"></div>
                            <div className="relative flex-shrink-0 mx-2 text-center text-white uppercase t__overline--o1">
                                {networks.length > 1
                                    ? t('index.tokenCard.selectNetwork')
                                    : t('index.tokenCard.stakeOn')}
                            </div>
                            <div className="w-full h-px bg-white"></div>
                        </div>
                        {/**
                         * Had to add styles like this. Tailwind needs purgable class names, and that's not possible with string manipulation.
                         * We wanted to use `grid-cols-{N}`, but not possible with the current engine. Resorting to CSS styles to achieve the same.
                         * @see https://tailwindcss.com/docs/content-configuration#dynamic-class-names
                         *
                         **/}
                        <div
                            className="grid gap-2 p-4 pt-2 md:pt-2 md:p-6"
                            style={{
                                gridTemplateColumns: `repeat(${networks.length}, minmax(0, 1fr))`,
                            }}
                        >
                            {networks.map((network, index) => (
                                <Button
                                    key={index}
                                    id="index-button"
                                    variant="primary-alt"
                                    aria-label={network.caption}
                                    onClick={() => onClick(network.slug)}
                                    prefix={
                                        <Image
                                            {...network.icon}
                                            asset={network.slug === 'polygon' ? 'matic-alt' : network.icon.asset}
                                        />
                                    }
                                    onHover={handleHover}
                                    className="relative flex items-center justify-center px-4 py-3 space-x-2 rounded-lg"
                                >
                                    {network.caption}
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    },
);
